import { AnimatePresence, motion } from "framer-motion";
import { Link } from "gatsby";
import React, { useState } from "react";
import useDocumentScrollThrottled from "~/hooks/useDocumentScrollThrottled";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { Icon, IconType } from "~/components/elements/Icon";
import Image from "~/components/elements/Image";
import PrevIcon from "~/assets/images/prev-icon.png";
import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import useBlogPosts from "~/hooks/useBlogPosts";

import { NavToggle } from "~/components/elements/buttons/NavToggle";
import { Images } from "../flexible/TabbedSlider";

export default function Header({ data, showPromoBar = false }) {
  const {
    header: { headerLink, headerLogo, headerMenu },
  } = data;

  const [activeMenu, setActiveMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showBanner, setShowBanner] = useState(showPromoBar);

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 50;

  const { allWpPost } = useBlogPosts();

  useDocumentScrollThrottled((callbackData) => {
    if (typeof window === "undefined") return;

    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setIsScrolled(isMinimumScrolled);

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const enterHandler = (i) => {
    setActiveMenu(i);
    setShowMenu(true);
  };

  const exitHandler = () => {
    setActiveMenu(null);
    setShowMenu(false);
  };

  return (
    <>
      <header
        onMouseLeave={() => setActiveMenu(null)}
        className={`hidden relative lg:fixed lg:block bg-gray-50 left-6 right-6 z-[150] ${
          showBanner ? "top-0" : "top-10"
        } `}
      >
        {showBanner && (
          <div className="bg-purple-2 max-w-[1350px] mx-auto">
            <Link
              to={allWpPost.nodes[0].uri}
              className="flex items-center justify-center w-full h-full text-[14px] py-2.5 tracking-[-0.02em]  transition-opacity hover:opacity-50 duration-300 ease-in-out"
            >
              <span className="mr-1 font-medium">Latest News</span> -{" "}
              {allWpPost.nodes[0].title}
              <div className="w-2 ml-1.5 mb-[2px] fw-icon">
                <Icon type={IconType.ArrowRightDown} />
              </div>
            </Link>
          </div>
        )}
        <div
          className={`relative bg-white ${
            showBanner ? "rounded-t-none" : "rounded-t-[10px]"
          } rounded-b-[10px] max-w-[1350px] mx-auto z-[10] transition-opacity duration-300 ease-in-out ${
            isScrolled ? " shadow" : "shadow-none"
          }`}
        >
          <div className="px-[26px] py-[18px] flex w-full items-center">
            <div className="flex items-center">
              <Link to={"/"}>
                <Image image={headerLogo} />
              </Link>
              <JoinTeam text={"Join our team!"} />
            </div>
            <div className="flex items-center space-x-5 lg:space-x-4 xl:space-x-12 ml-auto lg:mr-4 xl:mr-[60px]">
              {headerMenu?.map((menuItem, i) => (
                <HeaderNav
                  key={`navHead${i}`}
                  i={i}
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                  enterHandler={enterHandler}
                  exitHandler={exitHandler}
                  {...menuItem}
                />
              ))}
            </div>
            <HeaderLinks headerLink={headerLink} />
          </div>

          <AnimatePresence>
            {showMenu && headerMenu[activeMenu]?.subnav === "megamenu" && (
              <MegaMenu
                setActiveMenu={setActiveMenu}
                {...headerMenu[activeMenu]?.megaMenu}
              />
            )}
          </AnimatePresence>
        </div>
      </header>
      <MobileNav
        headerLink={headerLink}
        headerLogo={headerLogo}
        headerMenu={headerMenu}
      />
    </>
  );
}

const MobileNav = ({ headerMenu, headerLink, headerLogo }) => {
  const [showMenu, setSetShowMenu] = useState(false);

  return (
    <header className="">
      <div className="fixed lg:hidden top-0 flex py-[13px] items-center justify-between h-full max-h-[58px] border-b-[0.75px] border-white border-opacity-25 px-[15px] bg-dark-purple w-full z-[100] ">
        <div className="flex items-center h-full">
          <div onClick={() => setSetShowMenu(!showMenu)} className="w-[23px]">
            <NavToggle dark={true} isOpen={showMenu} />
          </div>

          <div className="h-full bg-white w-[0.5px] bg-opacity-[0.35] mx-[10px] ml-[8px] max-h-[34px] "></div>
          <Link to="/">
            <Image image={headerLogo} className="invert max-w-[83px] " />
          </Link>
        </div>

        <Link
          to={headerLink[1]?.link?.url}
          className="bg-purple-2 text-white hover:bg-dark-purple transition-colors duration-200 ease-in-out rounded-[3px] text-[11.8px] py-[10px] px-[40px] leading-[0.943] tracking-[-0.02em] font-normal"
        >
          {headerLink[1]?.link?.title}
        </Link>
      </div>
      <AnimatePresence>
        {showMenu && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="bg-dark-purple pt-[58px] fixed top-0 bottom-0 left-0 right-0  block w-screen h-screen  pb-12 overflow-y-auto lg:hidden z-[50]"
          >
            <div className="pt-[44px]">
              {headerMenu?.map((menuItem, i) => (
                <DropDown
                  i={i}
                  key={`menuItem${i}`}
                  link={menuItem?.link?.url}
                  label={menuItem?.link?.title}
                  onDudClick={() => {
                    setSetShowMenu(false);
                  }}
                  childItems={
                    (menuItem?.simple?.items?.length > 0 && menuItem?.simple) ||
                    (menuItem?.megaMenu?.items?.length > 0 &&
                      menuItem?.megaMenu)
                  }
                />
              ))}
            </div>

            <div className="flex justify-center flex-col items-center space-y-[11px] w-full mt-[54px] lg:mt-0 ">
              <span className="opacity-50">© Prevalent AI 2021</span>
              <div className="max-w-[30px] w-full h-full">
                <img src={PrevIcon} alt="" />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

const HeaderNav = ({
  link,
  subnav,
  i,
  enterHandler,
  exitHandler,
  simple,
  setActiveMenu,
  activeMenu,
}) => {
  return (
    <div className="relative">
      <Link
        onMouseEnter={() => enterHandler(i)}
        className="flex items-center transition-colors duration-200 hover:text-purple-2"
        to={link?.url}
      >
        <span>{link?.title}</span>
        {subnav !== "none" && (
          <div className="ml-[4px]">
            <Icon type={"caret"} />
          </div>
        )}
      </Link>

      <AnimatePresence>
        {subnav === "simple" && activeMenu === i && (
          <SimpleMenu setActiveMenu={setActiveMenu} items={simple?.items} />
        )}
      </AnimatePresence>
    </div>
  );
};

const MenuLinkItem = ({
  link,
  simpleLink,
  summary,
  contentClass = "text-black ",
  borderColour = "#000000",
  onClick = null,
  showBorder = true,
}) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <div
      className="cursor-pointer"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <Link
        onClick={onClick}
        to={link?.url || simpleLink?.url}
        className="group"
      >
        <div className="flex items-center justify-between">
          <span className="text-[21px] leading-[27px] tracking-[-0.01em] font-heading  ">
            {link?.title || simpleLink?.title}
          </span>
          <div className="transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100">
            <Icon type={"arrowRightDown"} />
          </div>
        </div>
        <div
          className={`mt-[5px] text-[16px] leading-[1.1] lg:leading-[1.475] tracking-[-0.02em] opacity-[0.31] font-body ${contentClass}`}
        >
          {summary}
        </div>
      </Link>
      {showBorder && (
        <div className="mt-[22px] w-full relative h-[1px]">
          <div className="absolute inset-0 bg-black bg-opacity-[.14]"></div>
          <motion.div
            animate={{
              width: onHover ? "100%" : 0,
            }}
            transition={{ duration: 0.3 }}
            className="absolute inset-0 bg-purple-2"
          />
        </div>
      )}
    </div>
  );
};

const MegaMenu = ({ cta, items, setActiveMenu }) => {
  const [activeImage, setActiveImage] = useState(0);

  return (
    <motion.div
      onMouseLeave={() => setActiveMenu(null)}
      className="absolute top-[calc(100%-10px)] left-0 right-0 z-0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="bg-white pt-2.5 rounded-b-lg">
        <div className="flex w-full ">
          <div className="max-w-[484px] flex flex-auto w-full bg-purple-4 rounded-bl-lg">
            <CTA {...cta} />
          </div>
          <div className="flex flex-auto w-full bg-white rounded-br-lg">
            <div className="flex w-full md:space-x-[30px] xl:space-x-[49px] pt-[19px] pr-[31px] pb-[45px] md:pl-[20px] xl:pl-[50px] items-center">
              <div className="space-y-[36px] pt-4">
                {items?.map((item, i) => (
                  <div
                    key={`megaItem${i}`}
                    onMouseEnter={() => setActiveImage(i)}
                  >
                    <MenuLinkItem {...item} />
                  </div>
                ))}
              </div>

              <div className="w-full h-full max-w-[380px] pointer-events-none self-stretch">
                <Images slides={items} active={activeImage} rounded fade />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const SimpleMenu = ({ setActiveMenu, items }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3 }}
    onMouseLeave={() => setActiveMenu(null)}
    className="absolute top-[45px] bg-white w-[438px] left-[-144px] rounded-b-lg"
  >
    <div className="pt-[30px] pb-[30px] px-[33px] space-y-[25px]">
      {items?.map((sItem, i) => (
        <MenuLinkItem
          key={`simpleItem${i}`}
          {...sItem}
          showBorder={i !== 0 ? false : true}
        />
      ))}
    </div>
  </motion.div>
);

const CTA = ({ image, link, content, heading }) => {
  return (
    <Link
      to={link?.url}
      className="lg:px-[20px] xl:px-[64px] pt-[48px] pb-[39px] h-full group"
    >
      <div className="flex flex-col justify-between h-full">
        <div className="max-w-[353px] h-full">
          <Heading type="h5" size={HeadingSizeOption.h5} text={heading} />

          <div className="mt-[18px]">
            <BodyCopy size={BodyCopySizeOption.Default} text={content} />
          </div>
        </div>
        <div className="flex items-end justify-between">
          <Button
            type={ButtonType.Text}
            link={link}
            icon={IconType.ArrowRightDown}
          />

          <Image
            image={image}
            className="max-w-[147px] w-full h-full pointer-events-none hidden xl:block"
          />
        </div>
      </div>
    </Link>
  );
};

const HeaderLinks = ({ headerLink }) => (
  <div className="flex items-center">
    <Link
      to={headerLink[0]?.link?.url}
      className="mr-2.5 xl:mr-5 leading-[0.943] opacity-40 hover:opacity-100 duration-200 ease-in-out "
    >
      {headerLink[0]?.link?.title}
    </Link>
    <Link
      to={headerLink[1]?.link?.url}
      className="bg-purple-2 text-white hover:bg-dark-purple transition  hover:scale-95 duration-200 ease-in-out rounded-[3px] text-[14px] py-[12px] px-[46px] leading-[1em] tracking-[-0.02em] font-normal"
    >
      {headerLink[1]?.link?.title}
    </Link>
  </div>
);

export const JoinTeam = ({ text }) => (
  <Link
    to="/careers"
    className="ml-[11px] bg-purple-1 bg-opacity-[0.20]  hover:bg-purple-2 hover:text-white text-purple-2 font-medium  duration-200 px-[7px] py-[2px] text-[12px] tracking-[-0.01em] rounded-[4px] leading-4  hidden xl:block hover:scale-[0.99] ease-in-out transition   "
  >
    {text}
  </Link>
);

export const DropDown = ({ link, label, childItems, i, onDudClick }) => {
  const [openMenu, setOpenMenu] = useState(i === 0 ? true : false);

  return (
    <div className="w-full ">
      <div
        className="flex items-center px-[27px] cursor-pointer"
        onClick={
          childItems?.items?.length > 1
            ? () => setOpenMenu(!openMenu)
            : undefined
        }
      >
        <div className="text-white w-full pb-[19px] text-[26px] leading-[25px] font-heading ">
          <Link
            onClick={childItems?.items?.length > 1 ? null : onDudClick}
            to={link || "#"}
          >
            {label}
          </Link>
        </div>
        {childItems?.items?.length > 1 && (
          <svg
            className={`transform rotate-180 transition-transform duration-200 ease-in-out mb-5 ${
              openMenu && "rotate-0"
            }`}
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10.7587"
              cy="10.2588"
              r="9.7412"
              transform="rotate(-90 10.7587 10.2588)"
              stroke="white"
            />
            <path
              d="M7.40676 9.80688L7.4001 9.80023C7.22426 9.60827 7.23337 9.31122 7.42007 9.13012L10.4252 6.12494C10.6088 5.94209 10.9055 5.94174 11.0894 6.12424L14.0963 9.13112C14.283 9.31222 14.2921 9.60926 14.1162 9.80123C14.0287 9.89231 13.9085 9.9438 13.7824 9.9452C13.6563 9.9466 13.5351 9.89687 13.4461 9.80789L11.6736 8.03539C11.5987 7.96042 11.4855 7.93765 11.3874 7.97828C11.2894 8.01892 11.2256 8.1149 11.226 8.22103L11.2252 14.0646C11.2256 14.1903 11.1748 14.3112 11.0848 14.3991C10.9951 14.4874 10.8735 14.5361 10.7478 14.5336C10.4872 14.5224 10.2833 14.3059 10.2872 14.0457L10.2854 8.21924C10.2854 8.11275 10.2217 8.01677 10.1233 7.97649C10.0252 7.93585 9.91205 7.95862 9.83743 8.03394L8.06564 9.80573C7.88314 9.98613 7.58959 9.98684 7.40674 9.80678L7.40676 9.80688Z"
              fill="white"
            />
          </svg>
        )}
      </div>
      {childItems?.cta && openMenu && (
        <motion.div
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.3 }}
          className="px-[11px] mt-[10px] mb-[29px]"
        >
          <Link
            onClick={onDudClick}
            to={childItems?.cta?.link.url}
            className="bg-purple-4 rounded-[5px] text-black block"
          >
            <div className="pt-[17px] pl-[19px] pr-[14px] pb-[26px]">
              <div className="text-[19px] leading-[24px] font-heading ">
                {childItems?.cta?.heading}
              </div>

              <div className="mt-[15px] ">
                <BodyCopy
                  size={BodyCopySizeOption.Default}
                  text={childItems?.cta?.content}
                />
              </div>
              <div className="flex items-end justify-between mt-5">
                <div>
                  <Button
                    type={ButtonType.Text}
                    link={childItems?.cta?.link}
                    icon={IconType.ArrowRightDown}
                  />
                </div>

                <Image
                  image={childItems?.cta?.image}
                  className="max-w-[95px] w-full h-full pointer-events-none"
                />
              </div>
            </div>
          </Link>
        </motion.div>
      )}
      <AnimatePresence exitBeforeEnter>
        {openMenu && (
          <div className="px-[27px] space-y-[24px] mb-[34px]">
            {childItems?.items?.map((childItem, i, arr) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.4,
                  delay: (i + 1) / 16,
                  ease: "easeIn",
                }}
                key={`mobileChildItem${i}`}
              >
                <MenuLinkItem
                  {...childItem}
                  onClick={onDudClick}
                  borderColour="#FFFFFF"
                  contentClass="text-white !opacity-60"
                />
              </motion.div>
            ))}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
