import React from "react";

export enum HeadingSizeOption {
  h1Large,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
}

export interface HeadingProps {
  size?: HeadingSizeOption;
  Type?: string;
  text: string;
}

export const Heading = ({ text, size, Type = "h3" }: HeadingProps) => {
  if (!text) return null;

  let sizeClasses = "text-h3";

  switch (size) {
    case HeadingSizeOption.h1Large:
      sizeClasses = "text-h1-large";
      break;
    case HeadingSizeOption.h1:
      sizeClasses = "text-h1";
      break;
    case HeadingSizeOption.h2:
      sizeClasses = "text-h2";
      break;
    case HeadingSizeOption.h3:
      sizeClasses = "text-h3";
      break;
    case HeadingSizeOption.h4:
      sizeClasses = "text-h4";
      break;
    case HeadingSizeOption.h5:
      sizeClasses = "text-h5";
      break;
    case HeadingSizeOption.h6:
      sizeClasses = "text-h6";
      break;
  }

  return (
    <Type
      className={`text-heading ${sizeClasses}`}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
};
