import { Link } from "gatsby";
import React, { Fragment } from "react";
import { Icon, IconType } from "~/components/elements/Icon";
import { WpAcfLink } from "~/_generated/types";

export enum ButtonType {
  Button = "button",
  Text = "text",
  Outline = "button-outline",
}

export interface ButtonProps {
  link: WpAcfLink;
  type: ButtonType;
  icon: string;
  className?: string;
}

export const Button = ({ link, type, icon, className = "" }: ButtonProps) => {
  if (!link) return null;

  let typeClasses = "";
  if (type === ButtonType.Button) {
    typeClasses = `button bg-purple-2 solid-button rounded-[4px] solid-btn py-[15px] px-[34px] lg:px-[48px] hover:bg-black hover:text-white hover:scale-95 ${className}   `;
  } else if (type === ButtonType.Text) {
    typeClasses = "text-link max-w-max";
  } else if (type === ButtonType.Outline) {
    typeClasses =
      "bg-transparent outline-button rounded-[4px] py-[15px] px-[34px] lg:px-[49px] hover:bg-white hover:scale-95 hover:text-black border border-black hover:border-white";
  } else {
    typeClasses = "hover:opacity-50 transition-opacity";
  }

  return (
    <Link
      to={link?.url || "#"}
      className={`group inline-block transition duration-200 ease-in-out w-full md:max-w-max 
    ${typeClasses}`}
    >
      <div
        className={`flex items-center justify-center w-full relative overflow-hidden ${
          type === ButtonType.Text && "pt-1"
        }`}
      >
        <div className="relative transition-transform duration-500 ease-in-out group-hover:-translate-y-full">
          <span>{link?.title}</span>
          <div className="inline-block -mt-0.5 ml-[16px]">
            <Icon type={icon} />
          </div>
        </div>
        <div
          className={`absolute transform translate-y-full group-hover:translate-y-0  ${
            type !== ButtonType.Text && "scale-110"
          } group-hover:scale-100 transition-transform duration-500 ease-in-out`}
        >
          <span>{link?.title}</span>
          <div className="inline-block -mt-0.5 ml-[16px]">
            <Icon type={icon} />
          </div>
        </div>
      </div>

      {type === ButtonType.Text && (
        <div className="w-full h-[1px] mt-[5px] link-underline "></div>
      )}
    </Link>
  );
};
