import { Link } from "gatsby";
import React from "react";

import { Button, ButtonType } from "~/components/elements/buttons/Button";
import Image from "~/components/elements/Image";
import { Icon, IconType } from "~/components/elements/Icon";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import ButtonGroup from "~/components/elements/buttons/ButtonGroup";

export const legalNav = [
  { url: "/privacy-policy", title: "Privacy Policy" },
];

export default function Footer({ data, hideFooterCta = false }) {
  const { footerNav, footerCta } = data?.footer;

  const scrollToTop = () => {
    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {!hideFooterCta && <FooterCta {...footerCta} />}
      <footer className="bg-dark-purple rounded-t-[20px] z-[100] lg:pb-[123px]  mx-auto -mt-5">
        <div className="pt-[40px] lg:pt-[94px] pb-[40px] xl:pb-0 container">
          <div className="relative items-baseline xl:flex">
            <div className="flex items-center justify-end md:justify-between  mb-[30px] xl:mb-0">
              <Image
                className="invert max-w-[126px] hidden md:block"
                image={footerNav?.logo}
              />

              <div
                onClick={scrollToTop}
                className="transition-opacity duration-200 cursor-pointer xl:hidden opacity-60 hover:opacity-100"
              >
                <Icon type={"scrollToTopButton"} />
              </div>
            </div>

            <div className="md:ml-auto">
              <h3 className="font-normal text-h3">
                {footerNav?.heading}
                <span className="mt-1 ml-1 text-purple sm:ml-0 sm:block">
                  {footerNav?.tagline}
                </span>
              </h3>
              <div className="mt-[31px] xl:mt-16 ">
                <Button
                  type={ButtonType.Button}
                  link={footerNav?.link}
                  icon={IconType.ArrowRightDown}
                />
              </div>

              <div className="mt-[48px] xl:mt-[84px]  grid grid-cols-2 gap-y-[36px] md:grid-cols-4 gap-x-[34px]  xl:h-[211px] ">
                {footerNav?.nav?.map((navItem, i) => (
                  <FooterNav key={`navGroup${i}`} {...navItem} />
                ))}
              </div>
            </div>

            <div
              onClick={scrollToTop}
              className="hidden transition-opacity duration-200 cursor-pointer xl:block opacity-60 hover:opacity-100 md:ml-12"
            >
              <div className="transform translate-y-4">
                <Icon type={"scrollToTopButton"} />
              </div>
            </div>

            <div className="hidden xl:block absolute -bottom-16 left-0 w-full max-w-[170px]">
              <Image image={footerNav?.graphic} className="w-full h-full" />
            </div>
          </div>
        </div>
        <LegalNav {...footerNav?.legalNav} links={legalNav} />
      </footer>
    </>
  );
}

const LegalNav = ({ icon, copyright, links }) => (
  <div className="lg:px-6">
    <div className="xl:mt-[142px] w-full bg-white bg-opacity-[0.12] rounded-t-[10px] lg:rounded-[10px] max-w-[1200px] mx-auto">
      <div className="px-[49px] py-[45px] lg:py-[35px] flex-col-reverse lg:flex-row flex justify-between items-center  ">
        <div className="flex justify-center lg:justify-start items-center space-x-[14px] mt-5 md:mt-[51px] lg:mt-0 w-full opacity-50 ">
          <div className="max-w-[30px] w-full h-full">
            <Image image={icon} className="w-full h-full " />
          </div>

          <span>© {copyright} 2021</span>
        </div>
        <div className="flex justify-center lg:justify-end w-full items-center space-x-[14px] p-small  ">
          {links?.map((link, i) => (
            <Link
              key={`legNav${i}`}
              className="transition-opacity opacity-50 hover:opacity-100 "
              to={link?.url}
            >
              {link?.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export const FooterNav = ({ heading, links }) => (
  <div className="pl-[24px] pr-5 border-l border-white border-opacity-30">
    <div className="text-[18px] leading-[22px] mb-[22px] block">{heading}</div>
    <div className="space-y-[4px] flex flex-col items-start space-y-">
      {links?.map((link, i) => (
        <Link
          key={i}
          to={link?.link?.url || "#"}
          className="relative inline-flex items-center w-auto transition-opacity duration-200 ease-in-out p-small opacity-60 hover:opacity-100 hover:text-green-2"
        >
          {link?.link?.title}

          {link?.link?.title === "Careers" && (
            <div className="relative ml-2 bg-[#4E5A6D] tracking-[-0.01em]  max-w-max text-[12px] rounded-[4px] px-[4px] py-[1px]">
              Hiring!
            </div>
          )}
        </Link>
      ))}
    </div>
  </div>
);

export const FooterCta = ({ content, heading, image, links }) => {
  return (
    <section className="mx-auto text-white bg-green-cta">
      <div className="container">
        <div className="flex flex-wrap py-[70px] md:pt-[139px] md:pb-[75px]">
          <div className="w-full md:w-7/12">
            <Border size={BorderSizeOption.Hero}>
              <Heading Type="h2" size={HeadingSizeOption.h1} text={heading} />

              <div className="mt-[35px] max-w-[503px]">
                <BodyCopy size={BodyCopySizeOption.Large} text={content} />
              </div>

              <div className="mt-12 md:mt-20 lg:mt-[166px]">
                <ButtonGroup links={links} className="!w-full md:!w-auto" />
              </div>
            </Border>
          </div>
          <div className="w-full md:w-5/12">
            <div className="md:ml-[76px]">
              <div className="mt-12 md:mt-20">
                <Image
                  image={image}
                  className="w-full h-full ml-auto md:max-w-[419px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
