import React from "react";
import { Icon } from "~/components/elements/Icon";

export enum SubheadingIconOption {
  Microchip = "microchip",
  Platform = "platform",
  Expertise = "expertise",
  Analytics = "analytics",
  Solutions = "solutions",
  MagnifyingGlass = "MagnifyingGlass",
  Legal = "legal",
}

export interface SubheadingProps {
  icon?: SubheadingIconOption;
  Type?: string;
  text: string;
}

export const Subheading = ({ text, icon, Type = "h3" }: SubheadingProps) => {
  if (!text) return null;

  return (
    <div className="bg-white max-w-max bg-opacity-30 rounded-[80px] flex items-center">
      <div className="bg-white rounded-full max-w-max p-[7px]">
        <Icon type={icon} />
      </div>
      <span className="text-[16px] leading-4 px-[10px] pr-[17px] pb-0.5 font-heading ">
        {text}
      </span>
    </div>
  );
};
