import React from "react";

export enum BodyCopySizeOption {
  Default,
  Small,
  Large,
}

export interface BodyCopyProps {
  text: string;
  size?: BodyCopySizeOption;
}

export const BodyCopy = ({ text, size }: BodyCopyProps) => {
  if(!text) return null;

  let sizeClasses = "p-small";
  if (size === BodyCopySizeOption.Small) {
    sizeClasses = "p-xsmall";
  }
  if (size === BodyCopySizeOption.Large) {
    sizeClasses = "p-large";
  }

  return (
    <div
      className={`${sizeClasses}`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
