import React from "react";

export enum BorderSizeOption {
  Default,
  Large,
  Hero,
}

const Border = ({
  size,
  children,
  textBorder = true,
  colour = "border-purple-2",
  disable = false,
  className = "",
}) => {
  let sizeClasses = "md:pl-[27px]";

  switch (size) {
    case BorderSizeOption.Default:
      sizeClasses = "md:pl-[27px]";
      break;
    case BorderSizeOption.Large:
      sizeClasses = "md:pl-[58px]";
      break;
    case BorderSizeOption.Hero:
      sizeClasses = "md:pl-[43px]";
      break;
  }

  if (textBorder) {
    return (
      <div
        className={` ${className} ${
          !disable ? "border-l flex-auto   pl-[19px]" : ""
        }  ${colour}  ${!disable && sizeClasses} `}
      >
        {children}
      </div>
    );
  }

  return children;
};

export default Border;
