import React from "react";
import { WpPage_Page_FlexibleContent_BlockLinks_Section } from "src/_generated/types";

export interface SectionProps
  extends WpPage_Page_FlexibleContent_BlockLinks_Section {
  layoutName?: string;
  children?: React.ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  previousSection?: SectionProps;
  nextSection?: SectionProps;
  backgroundColour?: string;
  classname?: string;
}

export const Section = (props: SectionProps) => {
  const {
    children,
    isFirst,
    isLast,
    previousSection,
    nextSection,
    layoutName = "default",
    borderTop,
    borderBottom,
    id,
    classname,
    backgroundColour,
    ...other
  } = props;

  const paddingClasses = getSectionPaddingClasses(
    backgroundColour,
    layoutName,
    previousSection,
    nextSection,
    borderTop
  );
  const colourClasses = getSectionColourClasses(backgroundColour);
  const layoutSpecificClasses = getLayoutSpecificClasses(layoutName);

  let classes = "mx-auto";
  if(layoutSpecificClasses){
    classes += " " + layoutSpecificClasses;
  }
  if(classname){
    classes += " " + classname;
  }
  if(paddingClasses){
    classes += " " + paddingClasses;
  }
  if(colourClasses){
    classes += " " + colourClasses;
  }
  if(borderTop === "curved"){
    classes += " rounded-t-[30px] !mt-[-30px] md:rounded-t-[60px] md:!mt-[-60px]"
  }
  if(layoutName !== "TwoColumnBlocks" && borderTop === "curved"){
    classes += " overflow-hidden"
  }
  if(layoutName !== "TwoColumnBlocks" && borderBottom === "curved"){
    classes += " overflow-hidden relative"
  }
  if(borderBottom === "curved"){
    classes +=  " rounded-b-[30px] md:rounded-b-[60px] overflow-hidden"
  }

  return (
    <section
      id={id}
      data-layout={layoutName}
      className={`${classes}`}
      {...other}
    >
      {children}
    </section>
  );
};

// Layout specific classes

function getLayoutSpecificClasses(layoutName: string) {
  let layoutSpecificClasses = "";
  return layoutSpecificClasses;
}

// Helper function to decide padding amounts on sections

function getSectionPaddingClasses(
  backgroundColour: string,
  layoutName: string,
  previousSection: SectionProps,
  nextSection: SectionProps,
  borderTop: string
) {
  const previousBackground =
    previousSection && previousSection.backgroundColour
      ? previousSection.backgroundColour
      : null;
  const nextBackground =
    nextSection && nextSection.backgroundColour
      ? nextSection.backgroundColour
      : null;

  const previousName =
    previousSection && previousSection.layoutName
      ? previousSection.layoutName
      : null;
  const nextName =
    nextSection && nextSection.layoutName ? nextSection.layoutName : null;

  let topPaddingClass = "pt-[25px] md:pt-[45px] xl:pt-[95px]";
  let bottomPaddingClass = "pb-[25px] md:pb-[45px] xl:pb-[95px]";

  // If next or previous item is not of the same background colour add top or bottom padding
  if (previousBackground !== backgroundColour) {
    topPaddingClass = "pt-[70px] md:pt-[90px] xl:pt-[180px]";
    if (layoutName === "BlockLinks" || layoutName === "TabbedSliderAlt") {
      topPaddingClass = "pt-[70px] md:pt-[90px] xl:pt-[120px]";
    }
  }
  if (nextBackground !== backgroundColour) {
    bottomPaddingClass = "pb-[70px] md:pb-[90px] xl:pb-[180px]";
    if (layoutName === "BlockLinks" || layoutName === "TabbedSliderAlt") {
      bottomPaddingClass = "pb-[70px] md:pb-[90px] xl:pb-[120px]";
    }
  }
  if (!nextName && backgroundColour === "black") {
    bottomPaddingClass = "";
  }

  if (layoutName === "Hero") {
    topPaddingClass = " pt-[105px] lg:pt-[210px] relative z-[10]";
    bottomPaddingClass = "rounded-b-[40px] md:rounded-b-[100px]";
  }

  if (
    previousSection?.backgroundColour === "white" &&
    nextSection?.backgroundColour === "white"
  ) {
    topPaddingClass = " mt-[70px] md:mt-32";
    bottomPaddingClass = " mb-[70px] md:mb-32 pb-[0px] md:pb-[0px] xl:pb-[0px]";
  }

  if (
    backgroundColour === "white" &&
    nextSection?.backgroundColour === "white"
  ) {
    bottomPaddingClass = " mb-[70px] md:mb-32 pb-[0px] md:pb-[0px] xl:pb-[0px]";
  }

  if (
    backgroundColour === "white" &&
    previousSection?.backgroundColour === "white"
  ) {
    topPaddingClass = " mt-[70px] md:mt-32";
    bottomPaddingClass = " mb-[70px] md:mb-32 pb-[0px] md:pb-[0px] xl:pb-[0px]";
  }

  if (nextSection?.borderTop === "curved") {
    bottomPaddingClass = " pb-[125px] md:pb-[13rem]";
  }

  if (previousSection?.borderBottom === "curved") {
    topPaddingClass = "pt-[125px] md:pt-[145px] xl:pt-[170px] mt-[-60px]";

    if (!nextSection) {
      bottomPaddingClass = "pb-[70px] md:pb-[90px] xl:pb-[120px]";
    }
  }

  if (
    layoutName === "ImageSlider" ||
    layoutName === "ContentSlider" ||
    layoutName === "PaginationSlider"
  ) {
    topPaddingClass = topPaddingClass + " overflow-hidden";
  }

  if (
    layoutName === "ContentSlider" &&
    previousSection.borderBottom === "curved"
  ) {
    topPaddingClass = "pt-[125px] md:pt-[145px] xl:pt-[170px] mt-[-60px]";
  }

  if (previousName === "Hero") {
    topPaddingClass = "pt-[145px] md:pt-[145px] xl:pt-[220px] mt-[-100px]";
  }

  if (layoutName === "ContactForm") {
    topPaddingClass = "pt-[165px] md:pt-[146px]  mt-[-100px]";
    bottomPaddingClass = "pb-[65px] md:pb-[85px] xl:pb-[130px]";
  }

  if (layoutName === "Marquee") {
    topPaddingClass = "";
    bottomPaddingClass = "";
  }

  if (
    layoutName === "Text" ||
    layoutName === "Image" ||
    layoutName === "Video"
  ) {
    // topPaddingClass = "pt-8 md:pt-12 lg:pt-20";
    topPaddingClass = "";
    bottomPaddingClass = "pb-8 md:pb-12 lg:pb-20";
  }

  if (layoutName === "Blockquote") {
    topPaddingClass = "";
    bottomPaddingClass = "pb-8 lg:pb-12";
  }

  return `${topPaddingClass} ${bottomPaddingClass}`;
}

// Helper function to decide colour classes by background colour

function getSectionColourClasses(backgroundColour: string) {
  let colourClasses = "";
  if (backgroundColour === "black") {
    colourClasses = "bg-black text-white";
  }
  switch (backgroundColour) {
    case "dark-purple":
      colourClasses = "bg-dark-purple text-white";
      break;
    case "grey":
      colourClasses = "bg-grey";
      break;
    case "purple":
      colourClasses = "bg-purple-2 text-white";
      break;
    case "light-purple":
      colourClasses = "bg-purple-4";
      break;
    case "light-purple-alt":
      colourClasses = "bg-purple-3";
      break;
    case "light-grey":
      colourClasses = "bg-light-grey";
      break;
    case "white":
      colourClasses = "bg-white";
      break;
    case "green":
      colourClasses = "bg-green-5";
      break;
    case "orange":
      colourClasses = "bg-orange-3";
      break;
    case "blue":
      colourClasses = "bg-blue-6";
      break;
    case "blue-bright":
      colourClasses = "bg-blue text-white";
      break;
  }
  return colourClasses;
}
