import React from "react";
import { Button, ButtonType } from "./Button";

const ButtonGroup = ({ links, className = "" }) => {
  return (
    <div
      className={`inline-flex flex-col md:flex-row md:flex space-y-[10px] md:space-y-0 md:space-x-[20px] w-auto ${className}`}
    >
      {/* Need to sort out type names from cms */}
      {links?.map((link, i) => (
        <Button key={i} type={link?.type} link={link?.link} icon={link?.icon} />
      ))}
    </div>
  );
};

export default ButtonGroup;
