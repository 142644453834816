import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";

import useCategories from "~/hooks/useCategories";
import useDocumentScrollThrottled from "~/hooks/useDocumentScrollThrottled";
import { Icon, IconType } from "~/components/elements/Icon";
import ExtraCurricularLogo from "~/assets/images/extra-curricular-logo.png";
import ExtraCurricularLogoDark from "~/assets/images/extra-curricular-logo-dark.png";
import { AnimatePresence, motion } from "framer-motion";
import FadeIn from "~/components/animations/FadeIn";
import useCategoryMenu from "~/hooks/useCategoryMenu";

export default function ResourcesHeader({
  setQuery,
  resourcesHeaderLight = false,
}) {
  if (typeof window === "undefined") return null;

  const [showSearch, setShowSearch] = useState(false);
  const [checkQuery, setCheckQuery] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery(checkQuery);
    setShowSearch(false);
    scrollToTop();
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const handleNavigate = (e) => {
    navigate(e);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(!isScrolled ? window.scrollY > 80 : window.scrollY > 81);
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { categories } = useCategoryMenu();

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-30 transition-colors duration-300 ease-in-out    ${
        resourcesHeaderLight
          ? "bg-purple-4 text-black"
          : "text-white bg-dark-purple"
      } `}
    >
      <div
        className={`flex flex-wrap border-b md:flex-nowrap  border-opacity-20 ${
          resourcesHeaderLight ? "border-b-black" : "border-b-white"
        }`}
      >
        <div
          className={`w-1/2 md:w-[244px] flex justify-center items-center border-r z-30 border-opacity-20 ${
            resourcesHeaderLight ? "border-r-black" : "border-r-white"
          } `}
        >
          <Link
            to="/"
            className="flex justify-center w-full py-4 space-x-2 text-current transition-opacity duration-300 ease-in-out md:py-0 hover:opacity-50"
            title="Prevalent AI"
          >
            <Icon type={IconType.ArrowLeft} />
            <div className="w-24">
              <Icon type={IconType.Logo} />
            </div>
            <span className="screenreader-text">Prevalent AI</span>
          </Link>
        </div>
        <div
          className={`${
            isScrolled ? " py-3 md:py-6" : "py-4 md:py-10"
          } transition-all z-30 duration-500 ease-in-out border-t border-white border-opacity-20 md:border-t-0 w-full md:w-auto md:flex-1 order-1 md:order-[0]`}
        >
          <Link to="/resources" className="block ">
            <img
              src={
                resourcesHeaderLight
                  ? ExtraCurricularLogoDark
                  : ExtraCurricularLogo
              }
              alt="Extra Curricular"
              className="w-[180px] md:w-[360px] mx-auto"
            />
          </Link>
        </div>
        <div
          className={`w-1/2 md:w-[244px] flex justify-center relative items-center z-30  md:border-l ${
            resourcesHeaderLight ? "border-l-black" : "border-l-white"
          } border-opacity-20 text-[18px]`}
        >
          <AnimatePresence>
            {showSearch ? (
              <FadeIn>
                <div
                  onClick={() => setShowSearch(false)}
                  className="flex items-center transition-opacity duration-300 ease-in-out cursor-pointer hover:opacity-60"
                >
                  <span className="mr-[7px]">Close</span>

                  <Icon type={IconType.Close} />
                </div>
              </FadeIn>
            ) : (
              <FadeIn>
                <div
                  className="flex items-center transition-opacity duration-300 ease-in-out cursor-pointer hover:opacity-60"
                  onClick={() => setShowSearch(true)}
                >
                  <Icon type={IconType.Search} />
                  <span className="ml-[7px] text-[16px]">Search Blog</span>
                </div>
              </FadeIn>
            )}
          </AnimatePresence>
        </div>
      </div>
      <AnimatePresence>
        {showSearch && (
          <motion.div
            className="fixed top-0 bottom-0 right-0 left-0 bg-dark-purple bg-opacity-[0.88]"
            style={{ willChange: "opacity" }}
            initial={{ opacity: 0, y: -"100%" }}
            animate={{ opacity: 1, y: "0%" }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          ></motion.div>
        )}
      </AnimatePresence>

      {showSearch ? (
        <div
          className={`flex items-center justify-center z-30 bg-dark-purple w-full border-b relative ${
            resourcesHeaderLight ? "border-b-black" : "border-b-white"
          } border-opacity-20`}
        >
          <div className="container z-30">
            <div className="w-full max-w-[997px] mx-auto py-16 md:py-[161px]">
              <div className="flex items-center justify-between w-full">
                <form
                  className="flex items-center justify-between w-full"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="text"
                    placeholder="Search blog"
                    className="bg-transparent appearance-none border-b-current  focus:outline-none text-[28px] max-w-[997px] "
                    onChange={(e) => setCheckQuery(e.target.value)}
                    autoFocus={showSearch}
                  />
                  <button type="submit" className="search-icon-big">
                    <Icon type={IconType.Search} />
                  </button>
                </form>
              </div>

              <motion.div
                initial={{ width: "0%" }}
                animate={{ width: "100%" }}
                transition={{ duration: 0.3 }}
                className={`w-full ${
                  resourcesHeaderLight ? "bg-black" : "bg-white"
                } h-[1px] mt-[31px]`}
              ></motion.div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`border-b ${
            resourcesHeaderLight ? "border-b-black" : "border-b-white"
          } border-opacity-20`}
        >
          <nav className={`hidden md:flex justify-center `}>
            <ul className="flex space-x-10">
              <li>
                <Link
                  to="/resources"
                  className={`${
                    isScrolled ? " py-3 md:py-6" : "py-4 md:py-10"
                  } hover:transition-colors hover:duration-300 hover:ease-in-out  ${
                    resourcesHeaderLight
                      ? "hover:text-purple"
                      : "hover:text-green-2"
                  } flex items-center`}
                  activeClassName={` border-b ${
                    resourcesHeaderLight
                      ? "text-purple border-b-purple"
                      : "text-green-2 border-b-green-2"
                  } `}
                >
                  <div className={`w-3 fw-icon `}>
                    <Icon type={IconType.All} />
                  </div>
                  <span className="ml-[10px]">All</span>
                </Link>
              </li>

              {categories?.map((category, i) => (
                <li key={`catNav${i}`}>
                  <Link
                    to={category?.category.uri}
                    className={`${
                      isScrolled ? " py-3 md:py-6" : "py-4 md:py-10"
                    } hover:transition-colors hover:duration-300 hover:ease-in-out ${
                      resourcesHeaderLight
                        ? "hover:text-purple"
                        : "hover:text-green-2"
                    } z-[150] flex items-center`}
                    activeClassName="text-green-2 border-b border-b-green-2"
                  >
                    <div
                      className={`w-3 fw-icon ${
                        category?.category?.category?.icon === "video" && "!w-4"
                      }`}
                    >
                      <Icon type={category?.category?.category?.icon} />
                    </div>
                    <span className="ml-[10px]">{category?.category.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="flex justify-center p-2 md:hidden">
            <form
              className="relative flex justify-center bg-transparent"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <select
                onChange={(e) => handleNavigate(e.target.value)}
                className="relative py-1 pr-4 text-current bg-transparent border-b outline-none appearance-none "
              >
                {categories?.map((category, i) => (
                  <option
                    key={`categoryNavItem${i}`}
                    value={category?.category?.uri}
                    selected={category?.category?.uri === location.pathname}
                  >
                    {category?.category?.name}
                  </option>
                ))}
              </select>
              <span
                className={`custom-arrow absolute right-0 top-0 h-full flex items-center text-current`}
              >
                <Icon type={IconType.DropDown} />
              </span>
            </form>
          </div>
        </div>
      )}
    </header>
  );
}
